import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import Text from 'Components/texts/Text'
import { ArticleFragment } from 'Typings/generated-gatsby-types'
import { localImageUrl } from 'Utils/api'
import styles from './ArticleContent.module.scss'

export type ArticleContentProps = {
  className?: string
  article: ArticleFragment
}

export const ArticleContent = (props: ArticleContentProps) => {
  const { className = '', article, ...other } = props

  if (!article) {
    return null
  }

  const { content } = article

  return (
    <Text
      className={classNames([styles.root, className])}
      Component="article"
      variant="sm"
      {...other}
    >
      <ReactMarkdown
        className={styles.content}
        transformImageUri={localImageUrl}
        linkTarget="_blank"
      >
        {content}
      </ReactMarkdown>
    </Text>
  )
}
