import React, { CSSProperties } from 'react'
import classNames from 'classnames'
import styles from './Divider.module.scss'

export type DividerProps = Pick<CSSProperties, 'width' | 'height'> & {
  className?: string
  direction?: 'horizontal' | 'vertical'
}

export const Divider = (props: DividerProps) => {
  const { className = '', direction = 'horizontal', width, height } = props

  return (
    <div
      style={{ width, height }}
      className={classNames([styles.root, className, styles[direction]])}
    />
  )
}
