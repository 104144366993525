import React from 'react'
import classNames from 'classnames'
import Text from 'Components/texts/Text'
import styles from './Badge.module.scss'

export interface BadgeProps {
  className?: string
  text?: string
}

export const Badge = (props: BadgeProps) => {
  const { className = '', text, ...other } = props

  if (!text) {
    return null
  }

  return (
    <Text className={classNames([styles.root, className])} {...other}>
      {text}
    </Text>
  )
}
