import React from 'react'
import AltPageLayout from 'Components/layout/AltPageLayout'
import Menu from 'Components/layout/Menu'
import {
  ArticlesData,
  formatArticles,
  getShortArticleContentForSEO,
  useMaybeChangeLanguageOnInit,
} from 'Utils/common'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import Row from 'Components/Row/Row'
import { StickyAside } from 'Components/StickyAside/StickyAside'
import { ArticlesRecommendations } from 'Components/Article/ArticlesRecommendations'
import { ArticleFragment } from 'Typings/generated-gatsby-types'
import { ArticleHeader } from 'Components/Article/ArticleHeader'
import { ArticleImage } from 'Components/Article/ArticleImage'
import { ArticleContent } from 'Components/Article/ArticleContent'
import { ArticleFooter } from 'Components/Article/ArticleFooter'
import { fadeUp } from 'Utils/animations'
import styles from './ArticlePage.module.scss'

export type ArticlePageProps = {
  slug: string
  articlesData: ArticlesData
  path: string
}

const ArticlePage = (props: ArticlePageProps) => {
  const { slug, articlesData, path } = props

  const isInit = useMaybeChangeLanguageOnInit()

  if (!isInit || !slug || !articlesData) {
    return null
  }

  const article = formatArticles(articlesData).find((i) => i.slug === slug)

  if (!article) {
    return null
  }

  const { title, image, main_tag: mainTag } = article

  const seo = {
    meta_title: title,
    meta_description: getShortArticleContentForSEO(article),
    meta_image: image,
  }

  return (
    <>
      <Menu />
      <AltPageLayout
        overflow={false}
        seo={seo}
        navs={['articles']}
        path={path}
        hasHeaderIndent={false}
        headerClassName={styles.layoutHeader}
        isArticle
      >
        <BaseContainer
          vertical="sm"
          className={styles.root}
          contentClassName={styles.container}
        >
          <ArticleHeader article={article} className={styles.header} />
          <Row vertical="top" className={styles.row}>
            <div className={styles.content}>
              <ArticleImage
                article={article}
                className={styles.image}
                {...fadeUp({ index: 2 })}
              />
              <ArticleContent article={article} className={styles.text} {...fadeUp()} />
              <ArticleFooter article={article} className={styles.footer} />
            </div>
            <StickyAside className={styles.aside} {...fadeUp({ index: 2 })}>
              <ArticlesRecommendations
                data={articlesData}
                ignoredSlug={slug}
                className={styles.recommendations}
              />
              <ArticlesRecommendations
                data={articlesData}
                type="same"
                tag={mainTag}
                ignoredSlug={slug}
                className={styles.recommendations}
              />
            </StickyAside>
          </Row>
        </BaseContainer>
      </AltPageLayout>
    </>
  )
}

export default ArticlePage
