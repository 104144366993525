import React, { useEffect } from 'react'
import ArticlePage from 'Views/articles/ArticlePage'
import { graphql, navigate, PageProps } from 'gatsby'
import { ArticlePageQuery } from 'Typings/generated-gatsby-types'
import { PATHS } from 'Utils/routing'

const Article = (props: PageProps<ArticlePageQuery>) => {
  const { data, path, pageContext = {} } = props

  const isEn = (pageContext as Record<string, any>).language === 'en'

  useEffect(() => {
    if (isEn) {
      navigate(PATHS.home)
    }
  }, [isEn])

  if (isEn) {
    return null
  }

  return (
    <ArticlePage
      slug={(pageContext as { slug: string }).slug}
      articlesData={data.allStrapiArticle}
      path={path}
    />
  )
}

export const query = graphql`
  query ArticlePage($language: String!) {
    allStrapiArticle(filter: { locale: { eq: $language } }) {
      edges {
        node {
          id
          title
          slug
          locale_key
          content
          main_tag
          additional_tags
          created_at
          image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Article
