import React from 'react'
import classNames from 'classnames'
import Text from 'Components/texts/Text'
import { ArticleFragment } from 'Typings/generated-gatsby-types'
import Row from 'Components/Row/Row'
import Calendar from 'Assets/calendar.inline.svg'
import { formatDate, getArticleLink } from 'Utils/common'
import { localImageUrl } from 'Utils/api'
import Touchable from 'Components/touchables/Touchable'
import styles from './ArticlesRecommendationsItem.module.scss'

export interface ArticlesRecommendationsItemProps {
  className?: string
  article: ArticleFragment
  type: 'last' | 'same'
}

export const ArticlesRecommendationsItem = (props: ArticlesRecommendationsItemProps) => {
  const { className = '', article, type } = props

  if (!article) {
    return null
  }

  const { title, created_at: createdAt, image, slug } = article

  const text = (
    <Text className={styles.title} variant="sm">
      {title}
    </Text>
  )

  const date = (
    <Text className={styles.date} variant="xs">
      {formatDate(createdAt)}
    </Text>
  )

  return (
    <Touchable
      link={getArticleLink(slug)}
      className={classNames([styles.root, className])}
    >
      {type === 'last' ? (
        <div className={styles.last}>
          {text}
          <Row className={styles.dateRow}>
            <Calendar className={styles.icon} />
            {date}
          </Row>
        </div>
      ) : type === 'same' ? (
        <Row className={styles.same} vertical="top">
          <div className={styles.left}>
            {date}
            {text}
          </div>
          <img className={styles.image} src={localImageUrl(image)} alt="" />
        </Row>
      ) : null}
    </Touchable>
  )
}
