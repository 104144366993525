import React, { ReactNode } from 'react'
import classNames from 'classnames'
import styles from './StickyAside.module.scss'

export type StickyAsideProps = {
  className?: string
  children: ReactNode
}

export const StickyAside = (props: StickyAsideProps) => {
  const { className = '', children, ...other } = props

  return (
    <aside className={classNames(styles.root, className)} {...other}>
      {children}
    </aside>
  )
}
