import React from 'react'
import classNames from 'classnames'
import { ArticleFragment } from 'Typings/generated-gatsby-types'
import { localImageUrl } from 'Utils/api'
import styles from './ArticleImage.module.scss'

export type ArticleImageProps = {
  className?: string
  article: ArticleFragment
}

export const ArticleImage = (props: ArticleImageProps) => {
  const { className = '', article, ...other } = props

  if (!article) {
    return null
  }

  const { image } = article

  return (
    <img
      className={classNames([styles.root, className])}
      src={localImageUrl(image)}
      alt=""
      {...other}
    />
  )
}
