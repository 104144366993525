import React, { Fragment } from 'react'
import classNames from 'classnames'
import { size } from 'lodash'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { ArticlesData, getSortedArticles } from 'Utils/common'
import { ArticlesRecommendationsItem } from 'Components/Article/ArticlesRecommendationsItem'
import Text from 'Components/texts/Text'
import { Divider } from 'Components/Divider/Divider'
import styles from './ArticlesRecommendations.module.scss'

export type ArticlesRecommendationsProps = {
  className?: string
  data: ArticlesData
  type?: 'last' | 'same'
  tag?: string
  ignoredSlug?: string
  itemsCount?: number
}

export const ArticlesRecommendations = (props: ArticlesRecommendationsProps) => {
  const { className = '', data, type = 'last', tag, ignoredSlug, itemsCount = 5 } = props

  const [t] = useTranslation()

  const items = getSortedArticles(
    data,
    type === 'same'
      ? ({ main_tag, slug }) => main_tag === tag && slug !== ignoredSlug
      : ({ slug }) => slug !== ignoredSlug,
  ).slice(0, itemsCount)

  if ([data.edges, items].some((list) => size(list) === 0)) {
    return null
  }

  return (
    <div className={classNames([styles.root, className])}>
      <Text className={styles.title} variant="smaller-bold">
        {t(`article.recommendations.${type}`)}
      </Text>
      <Divider />
      {items.map((article, index) => (
        <Fragment key={article.id}>
          <ArticlesRecommendationsItem article={article} type={type} />
          {index < size(items) - 1 && <Divider />}
        </Fragment>
      ))}
    </div>
  )
}
