import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Row from 'Components/Row/Row'
import { ArticleFragment } from 'Typings/generated-gatsby-types'
import Button from 'Components/touchables/Button'
import { ArticleTagsList } from 'Components/Article/ArticleTagsList'
import { fadeUp } from 'Utils/animations'
import styles from './ArticleFooter.module.scss'

export type ArticleFooterProps = {
  className?: string
  article: ArticleFragment
}

export const ArticleFooter = (props: ArticleFooterProps) => {
  const { className = '', article } = props

  const [t] = useTranslation()

  return (
    <Row horizontal="spaced" className={classNames([styles.root, className])}>
      <Button
        title={t('article.share')}
        className={styles.button}
        variant="outline"
        {...fadeUp()}
      />
      <ArticleTagsList article={article} className={styles.list} />
    </Row>
  )
}
