import React from 'react'
import classNames from 'classnames'
import { ArticleFragment } from 'Typings/generated-gatsby-types'
import Row from 'Components/Row/Row'
import { Badge } from 'Components/Badge/Badge'
import { fadeUp } from 'Utils/animations'
import styles from './ArticleTagsList.module.scss'

export type ArticleTagsListProps = {
  className?: string
  article: ArticleFragment
}

export const ArticleTagsList = (props: ArticleTagsListProps) => {
  const { className = '', article } = props

  return (
    <Row className={classNames([styles.root, className])}>
      {article.additional_tags.split('/').map((tag, index) => (
        <Badge key={tag} text={tag} {...fadeUp({ index: index + 1 })} />
      ))}
    </Row>
  )
}
