import React from 'react'
import classNames from 'classnames'
import Text, { TextProps } from 'Components/texts/Text'
import { ArticleFragment } from 'Typings/generated-gatsby-types'
import Row from 'Components/Row/Row'
import Calendar from 'Assets/calendar.inline.svg'
import { formatDate } from 'Utils/common'
import { Divider } from 'Components/Divider/Divider'
import { fadeUp } from 'Utils/animations'
import styles from './ArticleHeader.module.scss'

type TitleSize = 'base' | 'small'

const TITLE_PROPS: Record<TitleSize, Partial<TextProps>> = {
  base: { Component: 'h1', variant: 'heading' },
  small: { Component: 'h2', variant: 'lg-bold' },
}

export type ArticleHeaderProps = {
  className?: string
  size?: TitleSize
  article: ArticleFragment
}

export const ArticleHeader = (props: ArticleHeaderProps) => {
  const { className = '', size = 'base', article } = props

  if (!article) {
    return null
  }

  const { title, created_at: createdAt, main_tag: mainTag } = article

  return (
    <div className={classNames([styles.root, className])}>
      <Text className={styles.title} {...TITLE_PROPS[size]} {...fadeUp()}>
        {title}
      </Text>
      <Row className={styles.info}>
        <Row className={styles.dates} {...fadeUp({ index: 1 })}>
          <Calendar className={styles.icon} />
          <Text className={styles.text} variant="sm">
            {formatDate(createdAt)}
          </Text>
        </Row>
        <Divider direction="vertical" height={27} />
        <Text className={styles.text} variant="sm" {...fadeUp({ index: 2 })}>
          {mainTag}
        </Text>
      </Row>
    </div>
  )
}
